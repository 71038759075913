import axios                      from 'axios';
import { resetSubscriptionState } from '../../utils/subscription';
import { ITokens, IUser }         from '../interfaces/auth';
import { userPlatform }           from '../constants/constants';
import { PushNotifications } from '@capacitor/push-notifications';
import { isNative } from './platform';

const tokenType = 'Bearer';

export const setAuthorizationToken = (tokens?: ITokens): Promise<ITokens | undefined> => new Promise((resolve) => {
  if (tokens) {
    const storedToken = localStorage.authToken && JSON.parse(localStorage.authToken);

    const prevAccessToken = storedToken?.accessToken;
    const nextAccessToken = tokens?.accessToken;

    if (prevAccessToken !== nextAccessToken) {
      localStorage.setItem('authToken', JSON.stringify(tokens));
    }

    axios.defaults.headers.common.authorization = `${ tokenType } ${ nextAccessToken }`;
  } else {
    localStorage.removeItem('authToken');
    localStorage.removeItem('fcmRegistrationToken');
    delete axios.defaults.headers.common.authorization;
  }

  setTimeout(() => {
    resolve(tokens);
  }, 300);
});

export const setUserDetails = (user?: IUser) => {
  if (user) {
    localStorage.setItem('user', JSON.stringify(user));
  } else {
    localStorage.removeItem('user');
    localStorage.removeItem(userPlatform);
  }
};

export const logout = (): void => {
  setUserDetails();
  setAuthorizationToken();
  resetSubscriptionState();

  if (isNative) {
    PushNotifications.unregister().then(() => {
      PushNotifications.removeAllDeliveredNotifications().then(() => {
        PushNotifications.removeAllListeners().then(() => {
          localStorage.removeItem('fcmRegistrationToken');
          window.location.href = '/';
        });
      });
    });
  }
};
