import React, { useEffect } from 'react';
import {
  App,
  URLOpenListenerEvent,
}                           from '@capacitor/app';
import { useHistory }       from 'react-router-dom';

export const AppUrlListener: React.FC<any> = () => {
  let history = useHistory();

  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      // Example url: https://familycore-qa-app-service-ui.azurewebsites.net/#/auth/confirmation?email=yaroslav.zaklynskyi%40techfabric.com&code=853840
      // slug = /auth/confirmation?email=yaroslav.zaklynskyi%40techfabric.com&code=853840
      const slug = event.url.split('#').pop();
      if (slug) {
        history.push(slug);
      }
      // If no match, do nothing - let regular routing
      // logic take over
    });
  }, []);

  return null;
};
