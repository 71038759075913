import { Divider, Grid }                         from '@material-ui/core';
import axios, { AxiosError }                     from 'axios';
import { Form, Formik, FormikHelpers }           from 'formik';
import qs                                        from 'qs';
import React, { FC, useEffect, useState }        from 'react';
import { Link, useHistory }                      from 'react-router-dom';
import styled                                    from 'styled-components';
import { ReactComponent as ImageGroup }          from '../../../images/family-group.svg';
import { useAcceptInvitationRequest }            from '../../modules/Settings/components/Requests/queries';
import { LinkLikeText }                          from '../../shared/components/documents/DocumentPicker';
import { LogoBlue }                              from '../../shared/components/Logo';
import { TermsModal }                            from '../../shared/components/modals/Terms/TermsModal';
import { MuiButton }                             from '../../shared/components/MuiButton';
import { EAuthRoutes }                           from '../../shared/constants/constants';
import { PasswordInputField }                    from '../../shared/formFields/PasswordInputField';
import { TextInputField }                        from '../../shared/formFields/TextInputField';
import { camelize, queryParams }                 from '../../shared/functions';
import { AuthResponseKeys, IAuthResponse }       from '../../shared/interfaces/auth';
import { isNative }                              from '../../shared/utils/platform';
import { setAuthorizationToken, setUserDetails } from '../../shared/utils/setAuthorizationToken';
import { subscriptionName }                      from '../../utils/subscription';
import { IErrorResponse }                        from '../shared/interfaces';

import {
  AuthField,
  AuthSVGSceneHolder,
  PageHeader,
  PageHeaderText,
  ReverseGridContainer,
  SignInText,
  SignUpContainer,
  Terms,
  TextBlack,
}                                                from '../shared/styles';
import { signInFormSchema }                      from './validation';
import { PushNotifications } from '@capacitor/push-notifications';

interface ISignUpForm {
  fullName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const SignUpText = styled(SignInText)`
  margin-bottom: 18px;
`;

const TextLink = styled(Link)`
  font-size: 14px;
  font-weight: 700;
`;

export const SignIn: FC = () => {
  const [ termsModalOpened, setTermsModalOpened ] = useState(false);

  const { mutate: acceptRequest } = useAcceptInvitationRequest();

  const history = useHistory();
  const params  = queryParams();

  const displayDebugControls = !!localStorage.getItem('debug');

  const initialValues: ISignUpForm = {
    email           : params?.email as string,
    password        : '',
    confirmPassword : '',
    fullName        : '',
  };

  useEffect(() => {
    if (params?.familyUserId) {
      acceptRequest(params?.familyUserId as string, {
        onSuccess: () => {
          history.push(EAuthRoutes.SignIn);
        },
      });
    }
  }, [ params?.familyUserId ]);

  const handleSubmit = ({ email, password }: ISignUpForm, formikActions: FormikHelpers<ISignUpForm>) => {
    formikActions.setSubmitting(true);

    axios.post<IAuthResponse>('/api/v1/sessions', {
      email,
      password,
    })
      .then((response) => {
        const setTokenAndRedirectTo = (route: string) => {
          setUserDetails(response?.data?.data?.user);
          setAuthorizationToken(response.data.data.tokens)
            .then(() => {
              history.push(route);
            });
        };

        if (response.data.data.key === AuthResponseKeys.CREATE_SUBSCRIPTION || response.data.data.tokens) {
          setTokenAndRedirectTo(EAuthRoutes.SelectFamilyComponent);
        } else if (response.data.data.key === AuthResponseKeys.CREATE_FAMILY) {
          setTokenAndRedirectTo(EAuthRoutes.CreateFamily);
        } else {
          const { familyUserId } = queryParams();
          history.push({
            pathname : EAuthRoutes.CodeConfirmation,
            search   : qs.stringify({ email, familyUserId }),
          });
          formikActions.setSubmitting(false);
        }
      })
      .catch((err: AxiosError<IErrorResponse>) => {
        const errorDetails = err && err.response && err.response.data?.ErrorDetails;
        if (errorDetails) {
          const { Key, Message } = errorDetails;

          const filedNameMap: { [key: string]: string } = {
            [AuthResponseKeys.CREDENTIALS]   : 'password',
            [AuthResponseKeys.CREATE_FAMILY] : 'email',
          };

          const fieldName = filedNameMap?.[Key] || camelize(Key);

          formikActions.setErrors({
            [fieldName]: Message,
          });

          formikActions.setSubmitting(false);
          formikActions.setFieldError('email', "Something went wrong, please try again!");
        } else {
          formikActions.setFieldError('email', "Something went wrong, please try again!");
          formikActions.setSubmitting(false);
        }
      });
  };

  return (
    <>
      <SignUpContainer maxWidth="md">
        <PageHeader mb="20px">
          <LogoBlue />

          <PageHeaderText centeredXs>
            Sign In
          </PageHeaderText>
        </PageHeader>

        <ReverseGridContainer
          container
          spacing={3}
        >
          <Grid
            item
            sm={5}
            md={6}
            lg={7}
          >
            <AuthSVGSceneHolder>
              <ImageGroup />
            </AuthSVGSceneHolder>
          </Grid>

          <Grid
            item
            sm={7}
            md={6}
            lg={5}
          >
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={signInFormSchema}
              onSubmit={handleSubmit}
            >
              { ({
                handleChange,
                handleBlur,
                isSubmitting,
              }) => (
                <Form>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      <AuthField
                        label="Email"
                        name={'email'}
                        placeholder="you@example.com"
                        inputProps={{ maxLength: 100 }}
                        component={TextInputField}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                    >
                      <AuthField
                        label="Password"
                        name={'password'}
                        placeholder="At least 8 characters"
                        inputProps={{ maxLength: 50 }}
                        component={PasswordInputField}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                    >
                      <TextLink to={`${ EAuthRoutes.ForgotPassword }`}>Forgot Password</TextLink>
                    </Grid>
                  </Grid>

                  <MuiButton
                    fullWidth
                    type="submit"
                    margin="40px 0 0"
                  >
                    Sign In
                  </MuiButton>

                  <Divider />

                  <SignUpText>
                    Don’t have an account? <Link to={EAuthRoutes.SignUp}>Sign Up</Link>
                  </SignUpText>

                  <Terms>
                    Privacy <LinkLikeText onClick={() => setTermsModalOpened(true)}> Terms <TextBlack>&</TextBlack> Conditions.</LinkLikeText>
                  </Terms>

                  { displayDebugControls && (
                    <>
                      <button
                        type="button"
                        onClick={() => {
                          isNative && CdvPurchase.store.get(subscriptionName)?.getOffer()?.order();
                        }}
                      >
                        Test Subscription
                      </button>

                      <div>
                        <textarea
                          id="app-store-receipt"
                          style={{ marginTop: '15px', width: '100%', height: '150px' }}
                        />
                      </div>
                    </>
                  ) }
                </Form>
              ) }
            </Formik>
          </Grid>
        </ReverseGridContainer>
      </SignUpContainer>

      <TermsModal
        open={termsModalOpened}
        onClose={() => setTermsModalOpened(false)}
      />
    </>
  );
};
